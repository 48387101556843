<template>
  <div>
    <!-- <b-modal
      id="modalSatuSehat"
      size="xl"
      title="Pilih Rekam Medis"
      hide-footer
      @hide="hideModalSatuSehat"
    >
      <Table
        purpose="satusehat"
        @chooseData="chooseData"
        @selectData="selectData"
        route="ss-medical-records"
      />

      <div class="d-flex justify-content-end">
        <b-button
          variant="primary"
          @click="formOnSubmit"
          :disabled="patientCollection.length > 0 ? false : true"
          >Simpan</b-button
        >
      </div>
    </b-modal> -->
  </div>
</template>

<script>
import module from "@/core/modules/CrudModule.js";
import moduleBridge from "@/core/modules/CrudModuleBridge.js";
import validation from "@/core/modules/ValidationModule.js";
// import Table from "@/component/patients/TableMedicalRecord2.vue";
import {
  setTokenBridge,
  setTokenSatuSehat,
  setRefreshTokenBridge,
} from "@/core/services/jwt.service.js";
import ApiService from "@/core/services/api.service.js";
import {
  getSsOrganizationId,
  getSsLocationDefault,
  getConfiguration,
} from "@/core/services/jwt.service.js";

export default {
  components: {
    // Table,
  },

  props: {
    purpose: {
      type: String,
      default: "upload",
    },
  },

  data() {
    return {
      patientForm: {
        resourceType: "Patient",
        meta: {
          profile: ["https://fhir.kemkes.go.id/r4/StructureDefinition/Patient"],
        },
        identifier: [
          {
            use: "official",
            system: "https://fhir.kemkes.go.id/id/nik",
            value: "",
          },
        ],
        active: true,
        name: [
          {
            use: "official",
            text: "",
          },
        ],
        telecom: [
          {
            system: "phone",
            value: "",
            use: "mobile",
          },
          {
            system: "phone",
            value: "",
            use: "home",
          },
          {
            system: "email",
            value: "",
            use: "home",
          },
        ],
        gender: "",
        birthDate: "1945-11-17",
        deceasedBoolean: false,
        address: [
          {
            use: "home",
            line: [""],
            city: "",
            postalCode: "",
            country: "",
            extension: [
              {
                url: "https://fhir.kemkes.go.id/r4/StructureDefinition/administrativeCode",
                extension: [
                  {
                    url: "province",
                    valueCode: "",
                  },
                  {
                    url: "city",
                    valueCode: "",
                  },
                  {
                    url: "district",
                    valueCode: "",
                  },
                  {
                    url: "village",
                    valueCode: "",
                  },
                ],
              },
            ],
          },
        ],
        maritalStatus: {
          coding: [
            {
              system: "http://terminology.hl7.org/CodeSystem/v3-MaritalStatus",
              code: "",
              display: "",
            },
          ],
          text: "",
        },
        multipleBirthInteger: 0,
      },
      encounterStartForm: {
        resourceType: "Encounter",
        status: "arrived",
        class: {
          system: "http://terminology.hl7.org/CodeSystem/v3-ActCode",
          code: "AMB",
          display: "ambulatory",
        },
        subject: {
          reference: "",
          display: "",
        },
        participant: [
          {
            type: [
              {
                coding: [
                  {
                    system:
                      "http://terminology.hl7.org/CodeSystem/v3-ParticipationType",
                    code: "ATND",
                    display: "attender",
                  },
                ],
              },
            ],
          },
        ],
        period: {
          start: "",
        },
        statusHistory: [
          {
            status: "arrived",
            period: {
              start: "2022-06-14T07:00:00+07:00",
            },
          },
        ],
        serviceProvider: {
          reference: "Organization/",
        },
        identifier: [
          {
            system: `http://sys-ids.kemkes.go.id/encounter/${getSsOrganizationId()}`,
            value: "",
          },
        ],
      },
      encounterInprogressForm: {
        resourceType: "Encounter",
        id: "",
        identifier: [
          {
            system: `http://sys-ids.kemkes.go.id/encounter/${getSsOrganizationId()}`,
            value: "",
          },
        ],
        status: "in-progress",
        class: {
          system: "http://terminology.hl7.org/CodeSystem/v3-ActCode",
          code: "AMB",
          display: "ambulatory",
        },
        subject: {
          reference: "",
          display: "",
        },
        // participant: [
        //   {
        //     type: [
        //       {
        //         coding: [
        //           {
        //             system:
        //               "http://terminology.hl7.org/CodeSystem/v3-ParticipationType",
        //             code: "ATND",
        //             display: "attender",
        //           },
        //         ],
        //       },
        //     ],
        //     individual: {
        //       reference: "Practitioner/N10000001",
        //       display: "Dokter Bronsig",
        //     },
        //   },
        // ],
        participant: [],
        period: {
          start: "",
        },
        statusHistory: [
          {
            status: "arrived",
            period: {
              start: "",
              end: "",
            },
          },
          {
            status: "in-progress",
            period: {
              start: "",
              end: "",
            },
          },
        ],
        serviceProvider: {
          reference: `Organization/${getSsOrganizationId()}`,
        },
      },
      conditionForm: {
        resourceType: "Condition",
        clinicalStatus: {
          coding: [
            {
              system:
                "http://terminology.hl7.org/CodeSystem/condition-clinical",
              code: "active",
              display: "Active",
            },
          ],
        },
        category: [
          {
            coding: [
              {
                system:
                  "http://terminology.hl7.org/CodeSystem/condition-category",
                code: "encounter-diagnosis",
                display: "Encounter Diagnosis",
              },
            ],
          },
        ],
        code: {
          coding: [
            {
              system: "http://hl7.org/fhir/sid/icd-10",
              code: "K35.8",
              display: "Acute appendicitis, other and unspecified",
            },
          ],
        },
        subject: {
          reference: "",
          display: "",
        },
        encounter: {
          reference: "",
          display: "",
        },
      },
      encounterFinishForm: {
        resourceType: "Encounter",
        id: "",
        identifier: [
          {
            system: `http://sys-ids.kemkes.go.id/encounter/${getSsOrganizationId()}`,
            value: "",
          },
        ],
        status: "finished",
        class: {
          system: "http://terminology.hl7.org/CodeSystem/v3-ActCode",
          code: "AMB",
          display: "ambulatory",
        },
        subject: {
          reference: "",
          display: "",
        },
        participant: [],
        // participant: [
        //   {
        //     type: [
        //       {
        //         coding: [
        //           {
        //             system:
        //               "http://terminology.hl7.org/CodeSystem/v3-ParticipationType",
        //             code: "ATND",
        //             display: "attender",
        //           },
        //         ],
        //       },
        //     ],
        //     individual: {
        //       reference: "Practitioner/N10000001",
        //       display: "Dokter Bronsig",
        //     },
        //   },
        // ],
        period: {
          start: "",
          end: "",
        },
        statusHistory: [
          {
            status: "arrived",
            period: {
              start: "",
              end: "",
            },
          },
          {
            status: "in-progress",
            period: {
              start: "",
              end: "",
            },
          },
          {
            status: "finished",
            period: {
              start: "",
              end: "",
            },
          },
        ],
        serviceProvider: {
          reference: `Organization/${getSsOrganizationId()}`,
        },
      },
      patientCollection: [],
      encounterCollection: [],
      encounterInprogressCollection: [],
      medicalRecordIdCollection: [],
      conditionCollection: [],
      encounterFinishCollection: [],
      form: {
        clinic_id: getConfiguration().satu_sehat_clinic_id,
        data_patient: "",
        data_encounter_start: "",
        data_encounter_inprogress: "",
        data_condition: "",
        data_encounter_finish: "",
      },
    };
  },

  methods: {
    chooseData(item) {
      this.$emit("chooseData", item);
    },
    hideModalSatuSehat() {
      // ApiService.setHeader();
    },
    selectData(evt, item) {
      console.log("item", item);
      console.log("encounterStartForm", this.encounterStartForm);
      console.log(
        "encounterInprogressForm ======================================= ",
        this.encounterInprogressForm
      );
      this.encounterStartForm.participant = [
        {
          type: [
            {
              coding: [
                {
                  system:
                    "http://terminology.hl7.org/CodeSystem/v3-ParticipationType",
                  code: "ATND",
                  display: "attender",
                },
              ],
            },
          ],
        },
      ];
      this.encounterInprogressForm.participant = [];
      this.encounterFinishForm.participant = [];
      if (evt == 1) {
        this.setPatientForm(item);
        this.setEncounterStart(item);
        this.setEncounterInprogress(item);
        this.setCondition(item);
        this.setEncounterFinish(item);
      } else {
        for (let a = 0; a < this.patientCollection.length; a++) {
          if (this.patientCollection[a].medical_record_id == item.id) {
            this.patientCollection.splice(a, 1);
          }

          if (this.medicalRecordIdCollection[a] == item.id) {
            this.medicalRecordIdCollection.splice(a, 1);
          }

          if (this.encounterCollection[a].medical_record_id == item.id) {
            this.encounterCollection.splice(a, 1);
          }

          if (
            this.encounterInprogressCollection[a].medical_record_id == item.id
          ) {
            this.encounterInprogressCollection.splice(a, 1);
          }

          if (this.encounterFinishCollection[a].medical_record_id == item.id) {
            this.encounterFinishCollection.splice(a, 1);
          }

          if (this.conditionCollection[a].medical_record_id == item.id) {
            this.conditionCollection.splice(a, 1);
          }
        }
      }
    },

    setEncounterFinish(item) {
      this.encounterFinishForm.identifier[0].value = item.patient_unique_id;

      if (item.doctor_ihs_number.length > 0) {
        item.doctor_ihs_number.forEach((element, index) => {
          let participantData = {
            type: [
              {
                coding: [
                  {
                    system:
                      "http://terminology.hl7.org/CodeSystem/v3-ParticipationType",
                    code: "ATND",
                    display: "attender",
                  },
                ],
              },
            ],
            individual: {
              reference: `Practitioner/${element}`,
              display: item.doctor_name[index],
            },
          };

          let newParticipantData = { ...participantData };
          this.encounterFinishForm.participant.push(newParticipantData);
        });
      } else {
        let participantData = {
          type: [
            {
              coding: [
                {
                  system:
                    "http://terminology.hl7.org/CodeSystem/v3-ParticipationType",
                  code: "ATND",
                  display: "attender",
                },
              ],
            },
          ],
        };

        this.encounterFinishForm.participant.push(participantData);
      }

      if (item.ss_patient_id != null) {
        this.encounterFinishForm.subject.reference = `Patient/${item.ss_patient_id}`;
        this.encounterFinishForm.subject.display = item.patient_name;
      }

      this.encounterFinishForm.period.start = item.period.start_progress;
      this.encounterFinishForm.period.end = item.period.start_progress;

      if (item.appointment != null) {
        if (item.appointment.room != null) {
          this.encounterFinishForm.location = [
            {
              location: {
                reference: `Location/${item.appointment.room.ss_location_id}`,
                display: `${item.appointment.room.name}`,
              },
            },
          ];
        } else {
          this.encounterFinishForm.location = [
            {
              location: {
                reference: `Location/${getSsLocationDefault().ss_location_id}`,
                display: `${getSsLocationDefault().name}`,
              },
            },
          ];
        }
      } else {
        this.encounterFinishForm.location = [
          {
            location: {
              reference: `Location/${getSsLocationDefault().ss_location_id}`,
              display: `${getSsLocationDefault().name}`,
            },
          },
        ];
      }

      this.encounterFinishForm.statusHistory = [
        {
          period: {
            start: item.period.start_progress,
            end: item.period.start_progress,
          },
          status: "arrived",
        },
        {
          period: {
            start: item.period.start_progress,
            end: item.period.end_progress,
          },
          status: "in-progress",
        },
        {
          period: {
            start: item.period.start_progress,
            end: item.period.end_progress,
          },
          status: "finished",
        },
      ];

      let data = JSON.parse(JSON.stringify(this.encounterFinishForm));
      this.encounterFinishCollection.push(data);
    },

    setEncounterInprogress(item) {
      this.encounterInprogressForm.medical_record_id = item.id;
      console.log("econuter inprogresss", item);

      // participant: [
      //     {
      //       type: [
      //         {
      //           coding: [
      //             {
      //               system:
      //                 "http://terminology.hl7.org/CodeSystem/v3-ParticipationType",
      //               code: "ATND",
      //               display: "attender",
      //             },
      //           ],
      //         },
      //       ],
      //       // individual: {
      //       //   reference: "Practitioner/N10000001",
      //       //   display: "Dokter Bronsig",
      //       // },
      //     },
      //   ],

      if (item.doctor_ihs_number.length > 0) {
        item.doctor_ihs_number.forEach((element, index) => {
          let participantData = {
            type: [
              {
                coding: [
                  {
                    system:
                      "http://terminology.hl7.org/CodeSystem/v3-ParticipationType",
                    code: "ATND",
                    display: "attender",
                  },
                ],
              },
            ],
            individual: {
              reference: `Practitioner/${element}`,
              display: item.doctor_name[index],
            },
          };

          let newParticipantData = { ...participantData };
          this.encounterInprogressForm.participant.push(newParticipantData);
        });
      } else {
        let participantData = {
          type: [
            {
              coding: [
                {
                  system:
                    "http://terminology.hl7.org/CodeSystem/v3-ParticipationType",
                  code: "ATND",
                  display: "attender",
                },
              ],
            },
          ],
        };

        this.encounterInprogressForm.participant.push(participantData);
      }

      console.log("encounterInprogressForm", this.encounterInprogressForm);

      if (item.ss_patient_id != null) {
        this.encounterInprogressForm.subject.reference = `Patient/${item.ss_patient_id}`;
        this.encounterInprogressForm.subject.display = item.patient_name;
      }

      this.encounterInprogressForm.identifier[0].value = item.patient_unique_id;

      if (item.appointment != null) {
        if (item.appointment.room != null) {
          this.encounterInprogressForm.location = [
            {
              location: {
                reference: `Location/${item.appointment.room.ss_location_id}`,
                display: `${item.appointment.room.name}`,
              },
            },
          ];
        } else {
          this.encounterInprogressForm.location = [
            {
              location: {
                reference: `Location/${getSsLocationDefault().ss_location_id}`,
                display: `${getSsLocationDefault().name}`,
              },
            },
          ];
        }
      } else {
        this.encounterInprogressForm.location = [
          {
            location: {
              reference: `Location/${getSsLocationDefault().ss_location_id}`,
              display: `${getSsLocationDefault().name}`,
            },
          },
        ];
      }

      this.encounterInprogressForm.period.start = item.period.start_progress;
      this.encounterInprogressForm.statusHistory = [
        {
          period: {
            start: item.period.start,
            end: item.period.start_progress,
          },
          status: "arrived",
        },
        {
          period: {
            start: item.period.start_progress,
            end: item.period.end_progress,
          },
          status: "in-progress",
        },
      ];

      if (item.ss_patient_id != null) {
        this.encounterInprogressForm.subject.reference = `Patient/${item.ss_patient_id}`;
        this.encounterInprogressForm.subject.display = item.patient_name;
      }

      let data = JSON.parse(JSON.stringify(this.encounterInprogressForm));
      this.encounterInprogressCollection.push(data);
    },

    setEncounterStart(item) {
      this.encounterStartForm.medical_record_id = item.id;
      this.encounterStartForm.subject.display = item.patient_name;
      this.encounterStartForm.period.start = item.period.start;

      this.encounterStartForm.statusHistory[0].status = "arrived";
      this.encounterStartForm.statusHistory[0].period.start = item.period.start;
      this.encounterStartForm.serviceProvider.reference = `Organization/${getSsOrganizationId()}`;
      this.encounterStartForm.identifier[0].system = `http://sys-ids.kemkes.go.id/encounter/${getSsOrganizationId()}`;

      if (item.ss_patient_id != null) {
        this.encounterStartForm.subject.reference = `Patient/${item.ss_patient_id}`;
        this.encounterStartForm.identifier[0].value = `${item.ss_patient_id}`;
      }

      if (item.appointment != null) {
        if (item.appointment.room != null) {
          this.encounterStartForm.location = [
            {
              location: {
                reference: `Location/${item.appointment.room.ss_location_id}`,
                display: `${item.appointment.room.name}`,
              },
            },
          ];
        } else {
          this.encounterStartForm.location = [
            {
              location: {
                reference: `Location/${getSsLocationDefault().ss_location_id}`,
                display: `${getSsLocationDefault().name}`,
              },
            },
          ];
        }
      } else {
        this.encounterStartForm.location = [
          {
            location: {
              reference: `Location/${getSsLocationDefault().ss_location_id}`,
              display: `${getSsLocationDefault().name}`,
            },
          },
        ];
      }
      console.log("setEncounterStartForm", this.encounterStartForm);

      let data = JSON.parse(JSON.stringify(this.encounterStartForm));
      this.encounterCollection.push(data);
      console.log("this.encounterCollection", this.encounterCollection);
    },

    setPatientForm(item) {
      this.patientForm.medical_record_id = item.id;
      this.patientForm.patient_id = item.patient_id;
      this.patientForm.identifier[0].value = item.patient_id_card_number;
      this.patientForm.name[0].text = item.patient_name;
      // this.patientForm.telecom[0].value = item.patient_mobile_phone;
      // this.patientForm.telecom[1].value = item.patient_phone;
      this.patientForm.telecom[2].value = item.patient_email;
      this.patientForm.gender = item.patient_gender;
      this.patientForm.birthDate = item.patient_birt_date;
      this.patientForm.address[0].line[0] = item.patient_address;
      this.patientForm.address[0].city = item.patient_capital_name;
      this.patientForm.address[0].country = item.patient_country_name;
      this.patientForm.maritalStatus.coding[0].code = item.patient_status_code;
      this.patientForm.maritalStatus.coding[0].display =
        item.patient_status_display;
      this.patientForm.maritalStatus.text = item.patient_status_display;
      if (item.ss_patient_id != null) {
        this.patientForm.ss_patient_id = `${item.ss_patient_id}`;
      }
      this.patientForm.address[0].extension[0].extension[0].valueCode =
        item.patient_province_code_id;
      this.patientForm.address[0].extension[0].extension[1].valueCode =
        item.patient_capital_code_id;
      this.patientForm.address[0].extension[0].extension[2].valueCode =
        item.patient_subdistrict_id;
      this.patientForm.address[0].extension[0].extension[3].valueCode =
        item.patient_village_id;
      this.medicalRecordIdCollection.push(item.id);
      console.log('setPatientForm this.medicalRecordIdCollection', this.medicalRecordIdCollection);
      // let data = { ...this.patientForm };
      let data = JSON.parse(JSON.stringify(this.patientForm));

      this.patientCollection.push(data);
    },

    setCondition(item) {
      let dataObj = {
        data: [],
        medical_record_id: item.id,
      };
      // this.conditionForm.medical_record_id = item.id;
      item.diagnoses.forEach((element) => {
        if (item.ss_patient_id != null) {
          this.conditionForm.subject.reference = `Patient/${item.ss_patient_id}`;
          this.conditionForm.subject.display = item.patient_name;
          // this.conditionForm.code.coding[0].code = element.diagnose_code;
          this.conditionForm.code.coding[0].code =
            element.diagnose_code.replace(/\s+/g, "");
          this.conditionForm.code.coding[0].display = element.diagnose_name;
        }
        let data = JSON.parse(JSON.stringify(this.conditionForm));
        dataObj.data.push(data);
      });
      this.conditionCollection.push(dataObj);
    },

    async formOnSubmit() {
      // ApiBridge.setHeader();
      this.form.data_patient = JSON.stringify(this.patientCollection);
      this.form.data_encounter_start = JSON.stringify(this.encounterCollection);
      this.form.data_encounter_inprogress = JSON.stringify(
        this.encounterInprogressCollection
      );
      this.form.data_condition = JSON.stringify(this.conditionCollection);
      this.form.data_encounter_finish = JSON.stringify(
        this.encounterFinishCollection
      );
      // Make Request
      let response = await moduleBridge.submit(
        this.form,
        "preparations/multiple"
      );
      console.log("response here", response);
      // Check Response
      if (response.state == "error") {
        // ApiService.setHeader();
        // Validation Error
        this.error = validation.setValidationError(
          this.error,
          response.error.errors
        );
      } else if (response.state != "") {
        // ApiService.setHeader();
        console.log("call update medical record");
        this.updateMedicalRecord();
        // Success
        Swal.fire(response.success.title, response.success.message, "success");
        this.$emit("successAdd");
      } else {
        Swal.fire({
          title: "Gagal",
          text: "Harap hubungi vendor",
          icon: "error",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
      }
    },
    async updateMedicalRecord() {
      console.log("update medical record **************************************************************************");
      let form = {
        is_bridge_upload: 1,
        is_satusehat_reupload: 1,
        medical_record_id_collection: this.medicalRecordIdCollection,
        _method: "put",
      };
      console.log('vthis.medicalRecordIdCollection', this.medicalRecordIdCollection);
      // Make Request
      let response = await module.submitWithoutAlert(
        form,
        `medical-records/update/status`
      );
      console.log("responser here", response);
      // Check Response
      if (response.state != "error") {
        console.log("response != error", response);
        this.$emit("successPostingSatuSehatReupload");
        this.resetCollection();
      }
      this.$bvModal.hide("modalSatuSehat");
    },
    resetCollection() {
      console.log("reset collection");
      this.patientCollection = [];
      this.encounterInprogressCollection = [];
      this.medicalRecordIdCollection = [];
      this.conditionCollection = [];
      this.encounterFinishCollection = [];
      this.encounterCollection = [];
      this.encounterStartForm.participant = [
        {
          type: [
            {
              coding: [
                {
                  system:
                    "http://terminology.hl7.org/CodeSystem/v3-ParticipationType",
                  code: "ATND",
                  display: "attender",
                },
              ],
            },
          ],
        },
      ];
      this.encounterInprogressForm.participant = [];
      this.encounterFinishForm.participant = [];
    },
    setupEventListener() {
      // Hapus listener jika sudah ada sebelumnya (opsional)
      this.$root.$off("submitSatuSehatReupload");

      // Daftarkan listener baru
      this.$root.$on("submitSatuSehatReupload", () => {
        console.log("Listener dipanggil");
        this.formOnSubmit();
      });
    },
  },
  mounted() {
    this.$root.$on("setSatuSehatFormReupload", (evt, item) => {
      this.selectData(evt, item);
      console.log('jalannnnn "setSatuSehatForm asdasd"', this.purpose);
    });
    this.$root.$on("checkAllSatuSehatFormReupload", (items) => {
      items.forEach((element) => {
        if (element.is_valid == 1) {
          this.selectData(1, element);
        }
      });
    });
    this.$root.$on("uncheckAllSsMedicalRecordReupload", () => {
      this.resetCollection();
    });
    this.$root.$on("resetFormDataSatusehatReupload", () => {
      console.log("reset form data");
      this.resetCollection();
    });
    // this.$root.$once("submitSatuSehat", () => {
    //   console.log("harusnya jalan satu kali aja");
    //   this.formOnSubmit();
    // });
    this.setupEventListener();
  },
  beforeDestroy() {
    this.$root.$off("submitSatuSehatReupload");
  },
};
</script>

<style></style>